<script>
/* eslint-disable */
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

/**
 * Form-element component
 */
export default {
  page: {
    title: 'Form Elements',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {Layout, PageHeader},
  data() {
    return {
      title: 'Form Elements',
      items: [
        {
          text: 'Forms',
          href: '/',
        },
        {
          text: 'Form Elements',
          active: true,
        },
      ],
      selectedprimary: 'primary',
      selectedinfo: 'info',
      selecteddanger: 'danger',
      selectedwarning: 'warning',
      selectedsuccess: 'success',
      selectedToogle: 'A',
      selectedRadioDefault: 'a',
      selectedRadioDefault1: 'a',
      selectedRadioDefault2: 'a',
      selectedRadioDefault3: 'a',
      selectedRadioDefault4: 'a',
      selectedRadioDefault5: 'a',
      selectedRadioDefault6: 'a',
      selectedRadioDefault7: 'a',
      selectedRadioDefault8: 'a',
      selectedRadioDefault9: 'a',
      selectedRadioDefault10: 'a',
      selectedRadioDefault11: 'a',
      selectedRadioDefault12: 'a',
      selectedRadioDefault13: 'a',
      selectedRadioDefault14: 'a',
      status: 'accepted',
      rightcheck: 'accepted',
      primarycheck: 'accepted',
      successcheck: 'accepted',
      infocheck: 'accepted',
      warningcheck: 'accepted',
      dangercheck: 'accepted',
      primaryoutlinecheck: 'accepted',
      infooutlinecheck: 'accepted',
      warningoutlinecheck: 'accepted',
      dangeroutlinecheck: 'accepted',
      checked: true,
      checkedf: false,
      smchecked: true,
      lgchecked: true,
      selectedSwitch: [], // Must be an array reference!
      optionsSwtich: [
        {text: 'Red', value: 'red'},
        {text: 'Green', value: 'green'},
        {text: 'Yellow (disabled)', value: 'yellow', disabled: true},
        {text: 'Blue', value: 'blue'},
      ],

    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Textual inputs</h4>
            <p class="card-title-desc">
              Here are examples of
              <code>.form-control</code> applied to each textual HTML5
              <code>&lt;input&gt;</code>
              <code>type</code>.
            </p>

            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-form-group
                    id="example text"
                    class="mb-3"
                    label="Text"
                    label-cols-lg="2"
                    label-for="text"
                  >
                    <b-form-input
                      for="text"
                      value="Artisanal kale"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-search"
                    class="mb-3"
                    label="Search"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="search"
                  >
                    <b-form-input
                      id="search"
                      name="search"
                      type="search"
                      value="How do I shoot web"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-email"
                    class="mb-3"
                    label="Email"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      value="bootstrap@example.com"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-url"
                    class="mb-3"
                    label="URL"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="url"
                  >
                    <b-form-input
                      id="url"
                      name="url"
                      type="url"
                      value="https://getbootstrap.com"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-tel"
                    class="mb-3"
                    label="Telephone"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="tele"
                  >
                    <b-form-input
                      id="tele"
                      name="tel"
                      type="tel"
                      value="1-(555)-555-5555"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-password"
                    class="mb-3"
                    label="Password"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="pwd"
                  >
                    <b-form-input
                      id="pwd"
                      type="password"
                      value="password"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-number"
                    class="mb-3"
                    label="Number"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="number"
                  >
                    <b-form-input
                      id="number"
                      name="number"
                      type="number"
                      value="42"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-date-time"
                    class="mb-3"
                    label="Date and time"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="date-time"
                  >
                    <b-form-input
                      id="date-time"
                      type="datetime-local"
                      value="2019-08-19T13:45:00"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-date"
                    class="mb-3"
                    label="Date"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="date"
                  >
                    <b-form-input
                      id="date"
                      type="date"
                      value="2019-08-19"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-month"
                    class="mb-3"
                    label="Month"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="month"
                  >
                    <b-form-input
                      id="month"
                      type="month"
                      value="2019-08"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-week"
                    class="mb-3"
                    label="Week"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="week"
                  >
                    <b-form-input
                      id="week"
                      type="week"
                      value="2019-W33"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-time"
                    class="mb-3"
                    label="Time"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="time"
                  >
                    <b-form-input
                      id="time"
                      type="time"
                      value="13:45:00"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="example-color"
                    class="mb-3"
                    label="Color"
                    label-cols-lg="2"
                    label-cols-sm="2"
                    label-for="color"
                  >
                    <b-form-input
                      id="color"
                      class="form-control-color mw-100"
                      name="color"
                      type="color"
                      value="#556ee6"
                    ></b-form-input>
                  </b-form-group>

                  <div class="mb-3 row">
                    <label class="col-md-2 col-form-label">Select</label>
                    <div class="col-md-10">
                      <select class="form-control">
                        <option>Select</option>
                        <option>Large select</option>
                        <option>Small select</option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <label class="col-md-2 col-form-label" for="exampleDataList"
                    >Datalists</label
                    >
                    <div class="col-md-10">
                      <input
                        id="exampleDataList"
                        class="form-control"
                        list="datalistOptions"
                        placeholder="Type to search..."
                      />
                      <datalist id="datalistOptions">
                        <option value="San Francisco"></option>
                        <option value="New York"></option>
                        <option value="Seattle"></option>
                        <option value="Los Angeles"></option>
                        <option value="Chicago"></option>
                      </datalist>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Sizing</h4>
            <p class="card-title-desc">
              Set heights using size like
              <code>lg</code> and <code>sm</code>.
            </p>
            <div>
              <div class="mb-4">
                <b-form-input
                  id="input-md"
                  placeholder="Default input"
                ></b-form-input>
              </div>
              <div class="mb-4">
                <b-form-input
                  id="input-small"
                  placeholder=".form-control-sm"
                  size="sm"
                ></b-form-input>
              </div>
              <div class="mb-4">
                <b-form-input
                  id="input-lg"
                  placeholder=".form-control-lg"
                  size="lg"
                ></b-form-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Range Inputs</h4>
            <p class="card-title-desc">
              Set horizontally scrollable range inputs using
              <code>.form-control-range</code>.
            </p>

            <div>
              <h5 class="font-size-14">Example</h5>
              <input
                id="formControlRange"
                class="form-control-range form-range"
                type="range"
              />
            </div>
            <div class="mt-4">
              <h5 class="font-size-14">Custom Range</h5>
              <b-form-input
                id="custom-range"
                class="form-range"
                type="range"
              ></b-form-input>

              <b-form-input
                id="customRange2"
                class="mt-4 form-range"
                max="5"
                min="0"
                type="range"
              ></b-form-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Checkboxes</h4>

            <div class="row">
              <div class="col-xl-3 col-sm-6">
                <div class="mt-4">
                  <h5 class="font-size-14 mb-4">
                    <i class="mdi mdi-arrow-right text-primary me-1"></i>
                    Default Checkboxes
                  </h5>

                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="status"
                    name="checkbox-1"
                    unchecked-value="not_accepted"
                    value="not_accepted"
                  >
                    Default checkbox
                  </b-form-checkbox>

                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="status"
                    name="checkbox-1"
                    unchecked-value="not_accepted"
                    value="accepted"
                  >
                    Default checkbox Right
                  </b-form-checkbox>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6">
                <div class="mt-4">
                  <h5 class="font-size-14 mb-4">
                    <i class="mdi mdi-arrow-right text-primary me-1"></i> Form
                    Checkboxes Right
                  </h5>

                  <div class="form-check-right">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="status"
                      name="checkbox-1"
                      unchecked-value="not_accepted"
                      value="not_accepted"
                    >
                      Default checkbox
                    </b-form-checkbox>
                  </div>
                  <br/>
                  <div class="form-check-right">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="status"
                      name="checkbox-1"
                      unchecked-value="not_accepted"
                      value="accepted"
                    >
                      Default checkbox Right
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6">
                <div class="mt-4">
                  <h5 class="font-size-14">
                    <i class="mdi mdi-arrow-right text-primary me-1"></i> Custom
                    Checkboxes colors
                  </h5>
                  <p class="sub-header mb-4">
                    Add class
                    <code>.form-check-*</code> for a color Checkboxes
                  </p>

                  <div>
                    <div class="form-check-primary mb-3">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="status"
                        name="checkbox-1"
                        unchecked-value="not_accepted"
                        value="accepted"
                      >
                        Checkbox Primary
                      </b-form-checkbox>
                    </div>
                    <div class="form-check-success mb-3">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="status"
                        name="checkbox-1"
                        unchecked-value="not_accepted"
                        value="accepted"
                      >
                        Checkbox Success
                      </b-form-checkbox>
                    </div>
                    <div class="form-check-info mb-3">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="status"
                        name="checkbox-1"
                        unchecked-value="not_accepted"
                        value="accepted"
                      >
                        Checkbox Info
                      </b-form-checkbox>
                    </div>
                    <div class="form-check-warning mb-3">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="status"
                        name="checkbox-1"
                        unchecked-value="not_accepted"
                        value="accepted"
                      >
                        Checkbox Warning
                      </b-form-checkbox>
                    </div>
                    <div class="form-check-danger mb-3">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="status"
                        name="checkbox-1"
                        unchecked-value="not_accepted"
                        value="accepted"
                      >
                        Checkbox Danger
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6">
                <div class="mt-4">
                  <h5 class="font-size-14">
                    <i class="mdi mdi-arrow-right text-primary me-1"></i> Custom
                    Checkboxes Outline
                  </h5>
                  <p class="sub-header mb-4">
                    Add class
                    <code>.form-checkbox-outline</code> &amp;
                    <code>.form-check-*</code> for a color Checkboxes
                  </p>

                  <div>
                    <div class="form-checkbox-outline form-check-primary mb-3">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="status"
                        name="checkbox-1"
                        unchecked-value="not_accepted"
                        value="accepted"
                      >
                        Checkbox Outline Primary
                      </b-form-checkbox>
                    </div>

                    <div class="form-checkbox-outline form-check-success mb-3">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="status"
                        name="checkbox-1"
                        unchecked-value="not_accepted"
                        value="accepted"
                      >
                        Checkbox Outline Success
                      </b-form-checkbox>
                    </div>

                    <div class="form-checkbox-outline form-check-info mb-3">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="status"
                        name="checkbox-1"
                        unchecked-value="not_accepted"
                        value="accepted"
                      >
                        Checkbox Outline Info
                      </b-form-checkbox>
                    </div>
                    <div class="form-checkbox-outline form-check-warning mb-3">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="status"
                        name="checkbox-1"
                        unchecked-value="not_accepted"
                        value="accepted"
                      >
                        Checkbox Outline Warning
                      </b-form-checkbox>
                    </div>
                    <div class="form-checkbox-outline form-check-danger mb-3">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="status"
                        name="checkbox-1"
                        unchecked-value="not_accepted"
                        value="accepted"
                      >
                        Checkbox Outline Danger
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Radios</h4>

            <div class="row">
              <div class="col-xl-3 col-sm-6">
                <div class="mt-4">
                  <h5 class="font-size-14 mb-4">Default Radios</h5>

                  <b-form-group v-slot="{ ariaDescribedby1 }">
                    <b-form-radio v-model="selectedRadioDefault"
                                  :aria-describedby="ariaDescribedby1" name="some-radios1"
                                  value="b">Default Radio
                    </b-form-radio>
                    <b-form-radio v-model="selectedRadioDefault"
                                  :aria-describedby="ariaDescribedby1" name="some-radios1"
                                  value="a">Default Radio Right
                    </b-form-radio>
                  </b-form-group>

                </div>
              </div>

              <div class="col-xl-3 col-sm-6">
                <div class="mt-4">
                  <h5 class="font-size-14 mb-4">Form Radios Right</h5>
                  <div>
                    <b-form-group v-slot="{ ariaDescribedby }" class="form-check-right">
                      <b-form-radio v-model="selectedRadioDefault1"
                                    :aria-describedby="ariaDescribedby" name="some-radios2"
                                    value="b">Default Radio
                      </b-form-radio>
                      <b-form-radio v-model="selectedRadioDefault1"
                                    :aria-describedby="ariaDescribedby" name="some-radios2"
                                    value="a">Default Radio Right
                      </b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-sm-6">
                <div class="mt-4">
                  <h5 class="font-size-14">
                    <i class="mdi mdi-arrow-right text-primary me-1"></i> Form
                    Radio colors
                  </h5>
                  <p class="sub-header mb-4">
                    Add class <code>.form-radio-* </code> for a color Radios
                  </p>

                  <div>

                    <b-form-group v-slot="{ ariaDescribedby }" class="form-radio-primary">
                      <b-form-radio v-model="selectedRadioDefault2"
                                    :aria-describedby="ariaDescribedby" name="some-radios3"
                                    value="a">Radio Primary
                      </b-form-radio>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }" class="form-radio-success">
                      <b-form-radio v-model="selectedRadioDefault3"
                                    :aria-describedby="ariaDescribedby" name="some-radios4"
                                    value="a">Radio Success
                      </b-form-radio>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }" class="form-radio-info">
                      <b-form-radio v-model="selectedRadioDefault4"
                                    :aria-describedby="ariaDescribedby" name="some-radios5"
                                    value="a">Radio Info
                      </b-form-radio>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }" class="form-radio-warning">
                      <b-form-radio v-model="selectedRadioDefault5"
                                    :aria-describedby="ariaDescribedby" name="some-radios6"
                                    value="a">Radio Warning
                      </b-form-radio>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }" class="form-radio-danger">
                      <b-form-radio v-model="selectedRadioDefault6"
                                    :aria-describedby="ariaDescribedby" name="some-radios7"
                                    value="a">Radio Danger
                      </b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6">
                <div class="mt-4">
                  <h5 class="font-size-14">
                    <i class="mdi mdi-arrow-right text-primary me-1"></i> Form
                    Radio Outline
                  </h5>
                  <p class="sub-header mb-4">
                    Add class <code>form-radio-outline</code> &amp;
                    <code>.form-radio-* </code> for a color Checkboxes
                  </p>

                  <div>
                    <b-form-group v-slot="{ ariaDescribedby }"
                                  class="form-radio-outline form-radio-primary">
                      <b-form-radio v-model="selectedRadioDefault7"
                                    :aria-describedby="ariaDescribedby" name="some-radios8"
                                    value="a">Radio Outline Primary
                      </b-form-radio>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }"
                                  class="form-radio-outline form-radio-success">
                      <b-form-radio v-model="selectedRadioDefault8"
                                    :aria-describedby="ariaDescribedby" name="some-radios9"
                                    value="a">Radio Outline Success
                      </b-form-radio>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }"
                                  class="form-radio-outline form-radio-info">
                      <b-form-radio v-model="selectedRadioDefault9"
                                    :aria-describedby="ariaDescribedby" name="some-radios10"
                                    value="a">Radio Outline Info
                      </b-form-radio>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }"
                                  class="form-radio-outline form-radio-warning">
                      <b-form-radio v-model="selectedRadioDefault10"
                                    :aria-describedby="ariaDescribedby" name="some-radios11"
                                    value="a">Radio Outline Warning
                      </b-form-radio>
                    </b-form-group>
                    <b-form-group v-slot="{ ariaDescribedby }"
                                  class="form-radio-outline form-radio-danger">
                      <b-form-radio v-model="selectedRadioDefault11"
                                    :aria-describedby="ariaDescribedby" name="some-radios12"
                                    value="a">Radio Outline Danger
                      </b-form-radio>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Switches</h4>
            <p class="card-title-desc">
              A switch has the markup of a custom checkbox but uses the
              <code>.custom-switch</code> class to render a toggle switch.
              Switches also support the <code>disabled</code> attribute.
            </p>

            <div class="row">
              <div class="col-sm-6">
                <div>
                  <h5 class="font-size-14 mb-3">Switch examples</h5>

                  <div class="mb-3">
                    <b-form-checkbox v-model="checkedf" name="check-button" switch>
                      Default switch checkbox input <b>(Checked: {{ checkedf }})</b>
                    </b-form-checkbox>
                  </div>

                  <div class="mb-3">
                    <b-form-checkbox v-model="checked" name="check-button" switch>
                      Checked switch checkbox input <b>(Checked: {{ checked }})</b>
                    </b-form-checkbox>
                  </div>

                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                    label="Inline switch style checkboxes"
                  >
                    <b-form-checkbox-group
                      v-model="selectedSwitch"
                      :aria-describedby="ariaDescribedby"
                      :options="optionsSwtich"
                      switches
                    ></b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mt-4 mt-sm-0">
                  <h5 class="font-size-14 mb-3">Switch sizes</h5>
                  <b-form-checkbox size="sm" switch>Small</b-form-checkbox>
                  <b-form-checkbox switch>Default</b-form-checkbox>
                  <b-form-checkbox size="lg" switch>Large</b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">File browser</h4>

            <div>
              <h5 class="font-size-14">
                <i class="mdi mdi-arrow-right text-primary"></i> Default file
                input
              </h5>
              <div class="row">
                <div class="col-sm-6">
                  <div class="mt-3">
                    <label class="form-label" for="formFile"
                    >Default file input example</label
                    >
                    <input id="formFile" class="form-control" type="file"/>
                  </div>
                </div>
              </div>
              <!-- end row -->

              <div class="row">
                <div class="col-sm-6">
                  <div class="mt-4">
                    <div>
                      <label class="form-label" for="formFileSm"
                      >Small file input example</label
                      >
                      <input
                        id="formFileSm"
                        class="form-control form-control-sm"
                        type="file"
                      />
                    </div>
                  </div>
                </div>
                <!-- end col -->
                <div class="col-sm-6">
                  <div class="mt-4">
                    <div>
                      <label class="form-label" for="formFileLg"
                      >Large file input example</label
                      >
                      <input
                        id="formFileLg"
                        class="form-control form-control-lg"
                        type="file"
                      />
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>

            <div class="mt-4 pt-2">
              <h5 class="font-size-14 mb-0">
                <i class="mdi mdi-arrow-right text-primary"></i> Custom file
                input
              </h5>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="mt-4">
                  <div>
                    <label class="form-label">With Label</label>
                    <div class="input-group mb-3">
                      <label class="input-group-text" for="inputGroupFile01"
                      >Upload</label
                      >
                      <input
                        id="inputGroupFile01"
                        class="form-control"
                        type="file"
                      />
                    </div>
                    <div class="input-group">
                      <input
                        id="inputGroupFile02"
                        class="form-control"
                        type="file"
                      />
                      <label class="input-group-text" for="inputGroupFile02"
                      >Upload</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="mt-4">
                  <div>
                    <label class="form-label">With Button</label>
                    <div class="input-group mb-3">
                      <button
                        id="inputGroupFileAddon03"
                        class="btn btn-primary"
                        type="button"
                      >
                        Button
                      </button>
                      <input
                        id="inputGroupFile03"
                        aria-describedby="inputGroupFileAddon03"
                        aria-label="Upload"
                        class="form-control"
                        type="file"
                      />
                    </div>

                    <div class="input-group">
                      <input
                        id="inputGroupFile04"
                        aria-describedby="inputGroupFileAddon04"
                        aria-label="Upload"
                        class="form-control"
                        type="file"
                      />
                      <button
                        id="inputGroupFileAddon04"
                        class="btn btn-primary"
                        type="button"
                      >
                        Button
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
